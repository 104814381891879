<template>
  <div class="outer-mysubmit">
    <van-sticky>
      <div class="top-outer2">
        <div class="left type-select">
          <span class="type-css">审批类型：</span>
          <van-dropdown-menu active-color="#006C50" class="drop">
            <van-dropdown-item
              v-model="valueType"
              :options="option1"
              @change="changeSubmitType"
            />
          </van-dropdown-menu>
        </div>
        <!-- <div class="left">
          <span class="type-css">审批状态：</span>
          <van-dropdown-menu active-color="#006C50" class="drop">
            <van-dropdown-item
              v-model="value1"
              :options="option2"
              @change="changeSubmitType"
            />
          </van-dropdown-menu>
        </div> -->
        <!-- <div class="right" @click="selectTime">
          <span>时间：</span>
          <span>{{ date }}</span>
        </div> -->
      </div>
    </van-sticky>
    <van-calendar
      v-model="showDate"
      @confirm="onConfirm"
      :max-date="maxDate"
      :min-date="minDate"
      color="#006C50"
    />
    <div class="mlist">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          class="van-clearfix"
          v-model="loading"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
          <template v-if="dataList && dataList.length > 0">
            <div
              class="groups-outer"
              :key="index"
              v-for="(item, index) in dataList"
            >
              <div class="item" @click="showProcess(item)">
                <div class="status">
                  <!-- {{ getTaskResult({ result: item.result }).text }} -->
                    {{ item.status }}
                </div>
                <div class="line">
                  <div class="t-title">审批类型名称：</div>
                  <div class="t-txt">{{ item.processDefName }}</div>
                </div>
                <div class="line">
                  <div class="t-title">申请单号：</div>
                  <div class="t-txt">{{ item.instanceId }}</div>
                </div>
                <div class="line">
                  <div class="t-title">申请部门：</div>
                  <div class="t-txt">{{ item.staterUserDept }}</div>
                </div>
                <div class="line">
                  <div class="t-title">申请人：</div>
                  <div class="t-txt">{{ item.staterUser.name }}</div>
                </div>
                <div class="line">
                  <div class="t-title">摘要信息：</div>
                  <div class="t-txt">
                    <form-abstracts :value="item.formAbstracts"/>
                  </div>
                </div>
                <div class="line">
                  <div class="t-title">任务开始时间：</div>
                  <div class="t-txt">{{ item.startTime }}</div>
                </div>
                <div class="line">
                  <div class="t-title">处理完成时间：</div>
                  <div class="t-txt">{{ item.finishTime }}</div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no-data">
              <img src="../../../assets/image/n5.png" class="img" />
              <!-- <img src="../../assets/image/logo.png"/> -->
              <!-- <img src="../../assets/image/n5.png" class="img" /> -->
              <span class="txt">暂无内容</span>
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
    <el-drawer
      :size="isMobile ? '100%' : '500px'"
      direction="rtl"
      title="审批详情"
      :visible.sync="processVisible"
    >
      <instance-preview
        v-if="processVisible"
        :instance-id="selectInstance.instanceId"
        @handler-after="handlerAfter"
      ></instance-preview>
    </el-drawer>

    <w-dialog
      v-if="!isMobile"
      :title="`发起 - ${selectInstance.processDefName}`"
      closeFree
      width="1000px"
      v-model="openItemDl"
      okText="提 交"
      @cancel="openItemDl = false"
      @ok="submitForm"
    >
      <initiate-process
        ref="processForm"
        :node-id="selectInstance.nodeId"
        :instance-id="selectInstance.instanceId"
        :code="selectInstance.formId"
        v-if="openItemDl"
        @ok="openItemDl = false"
      ></initiate-process>
    </w-dialog>
  </div>
</template>

<script>
import taskApi from "@/api/processTask";
import moment from "moment";
import InstancePreview from "../approval/ProcessInstancePreview";
import InitiateProcess from "../InitiateProcess";
import { getProcTag } from "@/utils/ProcessUtil";
import { getTaskResult } from "@/utils/ProcessUtil";
import uniWebview from "@dcloudio/uni-webview-js";
import FormAbstracts from "@/views/workspace/oa/FormAbstracts.vue";

export default {
  name: "MySubmit",
  components: { InstancePreview, InitiateProcess, FormAbstracts },
  data() {
    return {
      total: 0,
      valueSearch: "",
      value1: "",
      valueType: 0,
      error: false,
      value2: 0,
      result: [],
      finished: false,
      refreshing: false,
      showDate: false,
      option1: [],
      option2: [
        { text: "全部", value: 0 },
        { text: "执行中", value: 1 },
        { text: "已结束", value: 2 },
      ],
      date: moment().format("YYYY-MM-DD"),
      maxDate: moment().toDate(),
      minDate: moment().subtract(30, "days").toDate(),
      params: {
        pageSize: 10,
        pageNo: 0,
        // finished: null,
        // code: "",
      },
      openItemDl: false,
      selectInstance: {},
      loading: false,
      processVisible: false,
      formList: [],
      dataList: [],
    };
  },
  computed: {
    isMobile() {
      return window.screen.width < 450;
    },
  },
  created() {
    //this.getMySubmitTypeList();
  },
  mounted() {},
  methods: {
    getTaskResult,
    getProcTag,
    sendTitle() {
      uniWebview.postMessage({
        data: { name: "协同-抄送我的", flag: "title" },
      });
    },
    changeSubmitType() {
      this.dataList = [];

      this.params.pageNo = 1;
      this.getSubmittedList();
    },
    onRefresh() {
      // 清空列表数据
      // console.log("刷新了")
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
        this.params.pageNo = 1;
      } else {
        //this.dataList = [];
        //加载下一页面的时候改下起始页数
        this.params.pageNo = this.params.pageNo + 1;
        if (this.error) {
          this.params.pageNo = 1;
        }
      }
      //  console.log("上拉加载数据之前看看参数"+JSON.stringify(this.params));
      this.getSubmittedList();
    },
    onConfirm(date) {
      this.showDate = false;
      this.date = moment(date).format("YYYY-MM-DD");
    },
    onSearch(value) {},
    selectTime() {
      this.showDate = true;
    },
    getSubmittedList() {
      if (this.option1.length > 0) {
        // console.log("有类型了")
        this.requestData();
      } else {
        //  console.log("没有类型有类型了")
        taskApi
          .mySubmitTypeList({})
          .then((rsp) => {
            let typeList = rsp.data.filter(
              (item) => item.id != 1 && item.id != 2
            );
            let ArrFilterDatas = [];
            typeList.map((item) => {
              ArrFilterDatas = [...ArrFilterDatas, ...item.items];
            });

            let temArr = [
              {
                formName: "全部",
                formId: 0,
              },
              ...ArrFilterDatas,
            ];
            this.option1 = temArr.map((item, index) => {
              return {
                text: item.formName,
                value: item.formId,
              };
            });
            // console.log("加载完类型了"+JSON.stringify(this.params));
            this.requestData();
          })
          .catch((e) => {
            // this.error = true;
            //this.loading = false;
          });
      }
    },
    requestData() {
      this.sendTitle();
      let paramsObj = {
        ...this.params,
        code: this.valueType != 0 ? this.valueType : "", //审批类型
        // formName: this.valueType > 0 ? this.option1[this.valueType].text : "", //审批类型
        //formName:this.option1[this.valueType].text,//审批类型
        finished: this.value1, //审批状态
      };
      taskApi
        .getCcMeListFilter(paramsObj)
        .then((rsp) => {
          this.loading = false;
          this.total = rsp.data.total;
          this.dataList = [...this.dataList, ...rsp.data.records];
          //console.log("还有下一页没看看当前长度"+this.dataList.length+"`总长度```"+ this.total)
          if (this.dataList.length < this.total) {
          } else {
            //  console.log("大于总数啊，没有下一页了")
            this.finished = true;
          }
        })
        .catch((e) => {
          this.error = true;
          //this.loading = false;
        });
    },
    //审批类型列表
    getMySubmitTypeList() {
      // console.log("发起类型请求了~~~~~~")
      //this.loading = true;
      taskApi
        .mySubmitTypeList({})
        .then((rsp) => {
          //console.log(rsp.data)
          //  { text: "全部", value: 0 },
          this.option1 = rsp.data.map((item, index) => {
            return {
              text: item,
              value: index,
            };
          });
          //  console.log("类型请求完了~~~~~~"+JSON.stringify(this.option1))
        })
        .catch((e) => {
          // this.error = true;
          //this.loading = false;
        });
    },
    reSubmit(row) {
      this.selectInstance = row;
      this.openItemDl = true;
      if (this.isMobile) {
        this.$router.push(
          `/mbinitiate?code=${row.formId}&instanceId=${row.instanceId}`
        );
      }
    },
    submitForm() {
      this.$refs.processForm.validate((validForm, validProcess) => {
        if (!this.isMobile) {
          if (validForm && validProcess) {
            this.$refs.processForm.submit();
          } else {
            this.$message.warning("请完善表单/流程选项😥");
          }
        }
      });
    },
    showProcess(row) {
      //console.log("``````````"+JSON.stringify(row))
      this.processVisible = true;
      this.selectInstance = row;
    },
    getDuration(row) {
      let end = this.$isNotEmpty(row.taskEndTime)
        ? row.taskEndTime
        : moment().format("YYYY-MM-DD HH:mm:ss");
      return this.$timeCoverStr(row.createTime, end);
    },
    handlerAfter() {
      this.processVisible = false;
      this.params.pageNo = 1;
      this.getSubmittedList();
      this.dataList = [];
    },
  },
  // watch: {
  //   params: {

  //     deep: true,
  //     handler() {
  //       console.log("有变化了我要更新")
  //       this.getSubmittedList();
  //     },
  //   },
  // },
};
</script>
<style scoped lang="less">
.btns {
  display: flex;
  justify-content: flex-end;
  .el-button--text {
    background-color: #006c50;
    color: #fff;

    padding: 0.5rem 0.7rem;
    font-size: 1.3rem;
    text-align: center;
    border-radius: 0.5rem;
  }
}
.type-select {
  /deep/ .van-dropdown-menu__title {
    width: 12rem;
  }
}
.outer-mysubmit {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: #fff;

  /deep/ .van-checkbox__icon {
    font-size: 2rem;
  }
  /deep/ .van-calendar__footer {
    .van-button {
      width: 100%;
    }
  }
  /deep/.van-dropdown-menu__bar {
    box-shadow: none !important;
    background-color: #fff;
  }
  /deep/ .el-drawer__header {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .el-drawer__close-btn {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .van-dropdown-menu__title {
    font-size: 1.3rem;
  }
  /deep/ .van-search {
    padding: 0;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  /deep/ .van-cell {
    line-height: 2.3rem;
    font-size: 1.3rem;
  }
  /deep/ .van-dropdown-menu__bar {
    height: 3rem;
  }
  /deep/ .van-sticky--fixed {
    background-color: #fff;
  }
  .groups-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .top-outer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    padding: 0 2rem;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 50vw;
    }
    .right {
      font-size: 1.3rem;
    }
    .more-submit {
      padding: 1rem 1.5rem;
      background-color: #006c50;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 2rem;
    }
  }
  .top-outer2 {
    margin-top: 1rem;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    font-size: 1.3rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    .left {
      display: flex;
      align-items: center;
      .type-css {
        padding-bottom: 0.18rem;
      }
    }
  }
  .mlist {
    width: 100%;
    padding: 2rem;
    /deep/ .van-checkbox-group {
      .van-checkbox__icon {
        margin-right: 1rem;
      }
      .van-checkbox__label {
        flex: 1;
      }
    }
    .item {
      padding: 0.8rem;
      flex: 1;

      background-color: #fff;
      border-radius: 1.5rem;
      box-sizing: border-box;
      box-shadow: 0px 0.2rem 1rem rgba(1, 12, 23, 0.06);

      margin-bottom: 1.5rem;
      position: relative;
      .line {
        display: flex;
        align-items: flex-start;
        padding: 0.5rem 0;
        .t-title {
          width: 12rem;
          font-size: 1.3rem;
          color: #333;
        }
        .t-txt {
          font-size: 1.3rem;
          color: #666;
        }
      }
      .status {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.3rem;
        background: #eef5f3;
        color: #006c50;
        line-height: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
        padding: 0rem 0.8rem;
        box-sizing: border-box;
      }
    }
  }
}
</style>

